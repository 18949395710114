/*  Full code on https://github.com/codescalper/weather-app-react
Author [Mayank Singh] */

@import url(https://cdnjs.cloudflare.com/ajax/libs/weather-icons/2.0.9/css/weather-icons.min.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --rad: 0.7rem;
  --dur: 0.3s;
  --color-dark: #2f2f2f;
  --color-light: #fff;
  --color-brand: #57bd84;
  --font-fam: "Lato", sans-serif;
  --height: 5rem;
  --btn-width: 6rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49);
}


body {
  background-color: #f5f5f5;
  background: var(--color-dark);
  height: 100vh;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.widget {
  display: flex;
  height: 400px;
  width: 650px;
  flex-wrap: wrap;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 27px 55px 0 rgba(0, 0, 0, 0.3),
    0 17px 17px 0 rgba(0, 0, 0, 0.15);
}
/* input search button style  */

.search {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.searchName {
  width: 50%;
  /* border: 3px solid #00b4cc; */
  border: none;
  padding: 16px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  font-size: 18px;
}

.searchName:focus {
  color: #00b4cc;
}

.searchButton {
  width: 80px;
  height: 32px;
  border: none;
  background: #048b9c;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
  outline: none;
  font-size: 16px;
}

.searchButton:hover {
  background-color: #17b8ce;
}

.wrap {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: -150px;
  margin-bottom: 50px;
}


.widget {
  display: flex;
  height: 400px;
  width: 650px;
  flex-wrap: wrap;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 27px 55px 0 rgba(0, 0, 0, 0.3),
    0 17px 17px 0 rgba(0, 0, 0, 0.15);
}
.widget .weatherIcon {
  flex: 1 100%;
  height: 50%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fafafa;
  font-family: weathericons;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 100px;
}

.widget .weatherInfo {
  flex: 0 0 70%;
  height: 40%;
  background: #080705;
  display: flex;
  align-items: center;
  color: white;
}
.widget .weatherInfo .temperature {
  flex: 0 0 40%;
  width: 100%;
  font-size: 65px;
  display: flex;
  justify-content: space-around;
  margin-right: 20px;
}
.widget .weatherInfo .description {
  flex: 0 60%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.widget .weatherInfo .description .weatherCondition {
  text-transform: uppercase;
  font-size: 35px;
  font-weight: 100;
}
.widget .weatherInfo .description .place {
  font-size: 15px;
}
.widget .date {
  flex: 0 0 30%;
  height: 40%;
  background: #70c1b3;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 30px;
  font-weight: 800;
  text-align: center;
}

.widget .extra-temp {
  flex: 1 100%;
  padding: 5% 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #fafafa;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  font-size: 100px;
}

.widget .extra-temp p {
  font-size: 25px;
  font-weight: 600;
}

.temp-info-minmax,
.weather-extra-info,
.two-sided-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  align-items: center;
  color: #70c1b3;
  
}

.widget .extra-temp .extra-info-leftside {
  text-align: left;
  font-weight: 600;
  text-transform: capitalize;
  color: #080705;
  font-size: 15px;
}

/* Small screens */
@media (max-width: 767px) {
  .widget {
    height: auto;
    width: 100%;
  }
  .widget .weatherIcon {
    height: 30%;
    font-size: 70px;
  }
  .widget .weatherInfo {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .widget .weatherInfo .temperature {
    font-size: 45px;
    margin-bottom: 10px;
  }
  .widget .weatherInfo .description {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
  }
  .widget .date {
    height: auto;
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
    text-align: center;
  }
  .widget .extra-temp {
    height: auto;
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    font-size: 50px;
  }
  .widget .extra-temp p {
    font-size: 16px;
  }
}

/* Larger screens */
@media (min-width: 768px) {
  .widget {
    height: 400px;
    width: 650px;
  }
  .widget .weatherIcon {
    height: 50%;
    font-size: 100px;
  }
  .widget .weatherInfo {
    height: 40%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .widget .weatherInfo .temperature {
    font-size: 65px;
    margin-right: 20px;
  }
  .widget .weatherInfo .description {
    font-size: 35px;
    text-align: left;
    margin-top: 0;
  }
  .widget .date {
    height: 40%;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .widget .extra-temp {
    height: auto;
    padding: 5% 0;
    grid-template-columns: repeat(2, 1fr);
    font-size: 100px;
  }
  .widget .extra-temp p {
    font-size: 25px;
  }
}

.glow {
  box-shadow: 0px 0px 20px #64e3e7;
  transition: box-shadow 1s ease-in-out;
}